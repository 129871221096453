import React from 'react';
import EmbedVideo from '../components/EmbedVideo';

import Layout from '../components/layout';
import SEO from '../components/seo';

const ThankYou = () => (
  <Layout>
    <SEO title="Thank You" />

    <div className="flex flex-col justify-center min-h-screen min-w-screen">
      <div className="mx-auto p-4 w-full sm:max-w-md md:max-w-xl xl:max-w-2xl 2xl:max-w-4xl">
        <div className="bg-red mb-16 mx-auto p-4 rounded-md shadow-md w-full xl:p-8">
          <EmbedVideo
            src="https://player.vimeo.com/video/597345260?h=244d40feb9"
            title="thank you"
            type="Vimeo"
          />
        </div>

        <h1 className="font-Oswald mb-12 text-2xl text-center md:text-4xl">
          Thank You for Applying, We Will be in Touch Soon!
        </h1>
      </div>
    </div>
  </Layout>
);

export default ThankYou;
